<template>
    <div class="banner">
<!--        <img class="bg" src="../../assets/bg_01.png">-->
        <div class="banner_cont">
            <div class="cont">
                <img class="bubi" src="../../../assets/bubi_01.png">
                <div class="banner_right">
                    <img class="txt_img" src="../../../assets/banner_txt.png">
                    <div class="banner_txt">
                        生活在这里的原住民我们称他们为【BUBI】，他们正依托于元宇宙社区的力量去建立一整套分布式数字IP的商业通证，并期待其拥有“超链接”能力，在物理世界破壁而出。实现0-∞的通证经济共享模式。
                    </div>
                </div>



            </div>

        </div>
    </div>

</template>

<script>
    export default {
        name: "Banner"
    }
</script>

<style scoped lang="less">
@import "../../../styles/imports";
    .banner {
        position: relative;
         background: url("../../../assets/bg_01.png") center top;
         background-size: cover;
        /*background-position: center;*/
         width: 100%;
        height: 760px;

        .bg {
            /*   position: absolute;
               top: 0;
               left: 0;*/
            width: 100%;
            object-fit: cover;
        }

        .banner_cont {
            position: absolute;
            top: 100px;
            width: 100%;

            .cont {
                max-width: @max-width;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
            }

            .bubi {
                max-width: 654px;
            }

            .banner_right {
                width: 420px;
                margin-top: 240px;
                margin-right: 80px;
                text-align: left;

                .txt_img {
                    width: 386px;
                }

                .banner_txt {
                    width: 100%;
                    font-size: 16px;
                    color: #fff;
                    font-weight: 500;
                    line-height: 30px;
                    margin-top: 27px;
                    text-align: left;
                    font-family: SH-Medium;
                }
            }

        }
    }
</style>
