<template>
    <div class="pass">
        <img class="pass_img1" src="../../../assets/Community_access_card.png">
        <div class="pass_main">
            <div class="title">- 社区通证 -</div>
            <div class="pass_cont">
                <div class="cont">
                    <img src="../../../assets/pass_01.png"/>
                    <div class="cont_right">
                        <div class="txt1">功能型通证</div>
                        <div class="txt2">BUBI正/反向的光</div>
                        <div class="txt3">可在指定日期进行跨平台转移【数字版权品】，便于社区成员流通交易。</div>
                    </div>
                </div>
                <div class="cont cont2">
                    <img src="../../../assets/pass_02.png"/>
                    <div class="cont_right">
                        <div class="txt1">权益型通证</div>
                        <div class="txt2">BUBI版权品
                        </div>
                        <div class="txt3">社区成员可将【数字版权品】与品牌进行跨界合作，共同打造联名款数字IP项目，可通过销售结果，获得一定比例的授权金。</div>
                    </div>
                </div>
            </div>
            <div class="pass_cont pass_cont2">
                <div class="cont">
                    <img src="../../../assets/pass_03.png"/>
                    <div class="cont_right">
                        <div class="txt1">支付型通证</div>
                        <div class="txt2">BUBI弹珠</div>
                        <div class="txt3">在购买【数字版权品】新品发售时，可使用，每次仅限使用1个，永久有效。
                            创意打开方式，以便构思、创建、和执行互动打开方式，以吸引更多的物理世界的原住民。
                        </div>
                    </div>
                </div>
                <div class="cont cont2">
                    <img src="../../../assets/pass_04.png"/>
                    <div class="cont_right">
                        <div class="txt1">服务型通证</div>
                        <div class="txt2">BUBI饼干</div>
                        <div class="txt3">体验设计之乐，BONI将为社区成员【数字版权品】进行回炉改造</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Pass"
    }
</script>

<style scoped lang="less">

    @import "../../../styles/imports";

    .pass {
        width: 100%;
        padding: 90px 0;
        background: url("../../../assets/bg_02.png") center top;
        background-size: cover;
        /*background-position: center;*/
        width: 100%;
        height: 960px;
        position: relative;

        .pass_img1 {
            position: absolute;
            left: calc((100% - 1400px) / 2);
            width: 1400px;
            height: auto;
            margin: 0 auto;
        }

        .pass_main {
            margin-top: 25px;
            width: 100%;

            .title {
                font-size: 60px;
                font-weight: 800;
                color: #fff;
                font-family: SH-Heavy;
            }

            .pass_cont2 {
                margin-top: 40px !important;
            }

            .pass_cont {
                max-width: @max-width;
                margin: 90px auto 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .cont2 {
                    margin-left: 20px;
                    margin-right: 0px !important;
                }

                .cont {
                    width: calc(50% - 20px);
                    background: #181818;
                    padding-right: 32px;
                    display: flex;
                    justify-content: flex-start;
                    color: #fff;
                    text-align: left;
                    margin-right: 20px;

                    img {
                        width: 222px;
                    }

                    .cont_right {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-left: 15px;
                    }

                    .txt1 {
                        font-size: 20px;
                        font-weight: 800;
                        font-family: SH-Heavy;
                    }

                    .txt2 {
                        margin-top: 20px;
                        font-size: 16px;
                        font-weight: 800;
                        font-family: SH-Heavy;
                    }

                    .txt3 {
                        margin-top: 20px;
                        font-size: 14px;
                        font-weight: 800;
                        line-height: 22px;
                        font-family: SH-Medium;
                    }
                }
            }
        }
    }
</style>
