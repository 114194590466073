<template>
    <div :class="[mobile == true?'mobile_main':'head']">
        <div class="head_cont">
            <img class="logo" src="../../assets/toplogo.png" @click="goHome">
            <div class="right">
                <img class="icon" src="../../assets/icon_gzh.png" @click="showWX">
                <div v-if="!mobile" class="hover">
                    <img class="wx_icon" src="../../assets/wx.jpg"/>
                </div>
                <!-- <img class="icon" src="../../assets/icon_xhs.png">
                 <img class="icon" src="../../assets/icon_dy.png">
                 <img class="icon" src="../../assets/icon_wb.png">-->
            </div>
        </div>
        <div v-if="isShowWX" class="mask" @click="isShowWX = false;">
            <div class="wx_cont">
                <img src="../../assets/wx.jpg">
                <div class="wx_txt">关注琢爵数字公众号</div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from "../../router";

    export default {
        name: "Header",
        props: ['mobile'],
        data() {
            return {
                isShowWX: false,
            }
        },
        mounted() {
            console.log(this.mobile)
        },
        methods: {
            goHome() {
                router.push('./')
            },
            showWX() {
                if (this.mobile) {
                    this.isShowWX = true;
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../styles/imports";

    .head {
        width: 100%;
        height: @banner-height;
        line-height: @banner-height;
        background-color: @mainColor;


        .head_cont {
            max-width: @max-width;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .logo {
            width: 208px;
            height: 49px;
        }

        .right:hover .hover {
            opacity: 1;
        }

        .right {
            position: relative;
            cursor: pointer;


            .icon {
                width: 24px;
                height: 24px;
                margin: 0 10px;
                display: inline-block;
                vertical-align: middle;
            }

            .hover {
                opacity: 0;
                position: absolute;
                top: 66px;
                right: 0px;
                width: 150px;
                height: 150px;
                z-index: 1000;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .mobile_main {
        width: 100%;
        height: @banner-height-h5;
        line-height: @banner-height-h5;
        background-color: @mainColor;


        .head_cont {
            margin: 0 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            position: absolute;
            top: 0;
            z-index: 1000;

            .wx_cont {
                position: absolute;
                top: calc(50% - 90px);
                left: calc(50% - 90px);

                img {
                    width: 180px;
                    height: 180px;
                }

                .wx_txt {
                    font-size: 13px;
                    color: #fff;
                    margin-top: -30px;
                    text-align: center;
                }
            }

        }

        .logo {
            /*width: 104px;*/
            height: 28px;
        }

        .right {
            .icon {
                width: 24px;
                height: 24px;
                margin: 0 10px;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
</style>
