<template>
    <div class="main"><div class="footer">© 2022 by zhuojue2046</div>
        <img class="footer_log" src="../../assets/footer_log.png"/></div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped lang="less">
    @import "../../styles/imports";
    .main{
        width: 100%;
        height: 100%;
        background: @mainColor;
        .footer {
            width: 100%;
            text-align: center;
            padding-top:20px;
            color:#fff;
        }

        .footer_log {
            width: 138px;
            margin: 20px auto;
        }
    }
</style>
